import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'

const LayoutContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'main main'
    'footer footer';
   grid-template-rows: auto 1fr auto;
  grid-template-columns: 250px 1fr;
  height: 100vh;
  padding-top: 90px; 

  @media (max-width: 720px) {
    grid-template-columns: 0 1fr;
  }
`

const HeaderArea = styled.div`
  grid-area: header;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: ${({ theme }) => theme.colors.header};
  padding: 1rem;
`

// const NavigationArea = styled.div`
//   grid-area: navigation;
//   background-color: ${({ theme }) => theme.colors.navigation};
//   padding: 1rem;

//  @media (max-width: 720px) {
//     display: none;
//   }
// `

const MainArea = styled.div`
  grid-area: main;
  top: 90px;
  background-color: ${({ theme }) => theme.colors.main};
  padding: 1rem;
`

const FooterArea = styled.div`
  grid-area: footer;
  background-color: ${({ theme }) => theme.colors.footer};
  text-align: center;
  padding: 1rem;
  
  @media (max-width: 720px) {
    padding: 0.5rem;
    font-size: 14px;
    line-height: 1.2;
    align-items: center;
  }

   @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutContainer>
      <HeaderArea>
        <Header />
      </HeaderArea>
      {/* <NavigationArea /> */}
      <MainArea>{children}</MainArea>
      <FooterArea>
        <Footer />
      </FooterArea>
    </LayoutContainer>
  )
}

export default Layout
