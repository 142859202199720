import React from 'react'
import styled from 'styled-components'

interface CustomTextFieldProps {
  label: string
  placeholder?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  helpText?: string // Mensagem de ajuda
  type?: string
  isError?: boolean
  name?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1.5rem;
`

const Label = styled.label<{ isError?: boolean }>`
  position: absolute;
  top: -8px;
  left: 12px;
  background-color: white;
  padding: 0 4px;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${({ isError }) => (isError ? '#d32f2f' : '#666')};
  pointer-events: none;
  transition: all 0.2s ease;
`

const Input = styled.input<{ isError?: boolean }>`
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid ${({ isError }) => (isError ? '#d32f2f' : '#ccc')};
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: ${({ isError }) => (isError ? '#d32f2f' : '#2A2532')};
    box-shadow: ${({ isError }) => (isError ? 'none' : '0 0 5px rgba(0, 123, 255, 0.5)')};
  }

  &:focus + ${Label} {
    color: ${({ isError }) => (isError ? '#d32f2f' : '#2A2532')};
  }
`

const HelpText = styled.span<{ isError?: boolean }>`
  margin-top: 4px;
  font-size: 0.8rem;
  color: ${({ isError }) => (isError ? '#d32f2f' : '#666')};
`

const CustomTextField: React.FC<CustomTextFieldProps> = ({ label, placeholder = '', value, onChange, helpText = '', type = 'text', isError = false }) => {
  return (
    <Wrapper>
      <Input type={type} placeholder={placeholder} value={value} onChange={onChange} isError={isError} />
      <Label isError={isError}>{label}</Label>
      {/* Exibe o texto de ajuda apenas se houver mensagem e for erro */}
      {helpText && isError && <HelpText isError={isError}>{helpText}</HelpText>}
    </Wrapper>
  )
}

export default CustomTextField
