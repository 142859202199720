import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../../components/CustoButtonSX'
import Layout from '../Layout'
import CustomInput from '../CustomInput'
import { signIn } from '../../api/login'
import { removeAllWithPrefix, setEncryptedItem } from '../../utils/storageUtils'
import { isAxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

interface APIToken {
  token: string
  usuario: {
    id: number
    email: string
    nome: string
  }
}

// Schema de validação usando Zod
const loginSchema = z.object({
  email: z.string().email('Por favor, insira um e-mail válido.'),
  password: z.string().min(3, 'A senha deve ter pelo menos 3 caracteres.'),
})

// Tipos para o formulário
type LoginFormInputs = z.infer<typeof loginSchema>

// Container para o formulário com responsividade
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`

const FormTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`

const StyledButton = styled(CustomButton)`
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
  }
`

const Login: React.FC = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: zodResolver(loginSchema),
  })

  const { mutateAsync: authenticate } = useMutation({
    mutationFn: signIn,
  })

  const onSubmit: SubmitHandler<LoginFormInputs> = async data => {
    try {
      const apiToken: APIToken = await authenticate({
        email: data.email,
        senha: data.password,
      })

      if (apiToken) {
        // Armazenar dados no localStorage (criptografado)
        removeAllWithPrefix('@tbasbr')
        setEncryptedItem('@tbasbr', 'token', apiToken.token)
        setEncryptedItem('@tbasbr', 'usuario', apiToken.usuario.id.toString())
        setEncryptedItem('@tbasbr', 'email', apiToken.usuario.email.toString())
        setEncryptedItem('@tbasbr', 'nome', apiToken.usuario.nome.toString())

        toast.success('Autenticado com sucesso!')
        navigate('/dashboard') // Redirecionar para o dashboard
      } else {
        toast.error('Credenciais inválidas.')
      }
    } catch (error: unknown) {
      let message = 'Ocorreu um erro inesperado.'
      if (isAxiosError(error)) {
        message = error.response?.data.message || 'Erro de autenticação.'
      }
      toast.error(message)
      console.error(message)
    }
  }

  return (
    <Layout>
      <FormWrapper>
        <FormTitle>Bem-vindo à sua conta!</FormTitle>
        <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
          <CustomInput label="E-mail" placeholder="Digite seu e-mail" value={getValues('email')} {...register('email')} isError={!!errors.email} helpText={errors.email?.message || 'Tudo certo!'} />
          <CustomInput
            label="Senha"
            placeholder="Digite sua senha"
            type="password"
            value={getValues('password')}
            {...register('password')}
            isError={!!errors.password}
            helpText={errors.password?.message || 'Tudo certo!'}
          />
          <StyledButton label="Entrar" variant="outlined" customcolor="#2A2532" type="submit" size="large" />
        </StyledForm>
      </FormWrapper>
    </Layout>
  )
}

export default Login
