import { api } from '../lib/axios'

export interface SignInBody {
  email: string
  senha: string
}

export async function signIn({ email, senha }: SignInBody) {
  const response = await api.post('/token', { email, senha })
  return response.data
}
