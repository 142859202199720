import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../components/pages/Home'
import PoliticaDePrivacidade from '../components/pages/PoliticaDePrivacidade'
import TermoDeUso from '../components/pages/TernoDeUso'
import Sobre from '../components/pages/Sobre'
import Error404 from '../components/pages/404'
import PrivateRoute from './PrivateRoute'
import Login from '../components/pages/Login'
import Registro from '../components/pages/Registro'

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/registro" element={<Registro />} />
      <Route path="/sobre" element={<Sobre />} />
      <Route path="/politica-de-privacidade" element={<PrivateRoute element={<PoliticaDePrivacidade />} private={true} />} />
      <Route path="/termo-de-uso" element={<PrivateRoute element={<TermoDeUso />} private={true} />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default AppRoutes
