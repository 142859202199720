import React from 'react'
import Layout from '../Layout'
import styled from 'styled-components'

const Content = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
`

const Title = styled.h1`
  font-size: 2rem;
  color: #2A2532;
  text-align: center;
  margin-bottom: 1rem;
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  color: #2A2532;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
`

const Highlight = styled.span`
  color: #18131C;
  font-weight: bold;
`

const CenteredList = styled.ul`
  list-style: none; /* Remove bullets */
  padding: 0;
  margin: 1rem auto; /* Centraliza a lista */
  text-align: center; /* Centraliza o conteúdo */
`

const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
`

const PoliticaDePrivacidade: React.FC = () => {
  return (
    <Layout>
      <Content>
        <Title>Política de Privacidade</Title>
        <Paragraph>
          Nossa política de privacidade reflete o compromisso da <Highlight>TBAS</Highlight> em proteger seus dados e respeitar sua privacidade. Leia atentamente os termos abaixo.
        </Paragraph>

        <SectionTitle>1. Informações Coletadas</SectionTitle>
        <Paragraph>
          Coletamos informações pessoais fornecidas voluntariamente por você, como nome, e-mail, endereço e informações de pagamento, quando você se cadastra ou faz uso dos serviços da nossa
          organização.
        </Paragraph>

        <SectionTitle>2. Uso das Informações</SectionTitle>
        <Paragraph>
          As informações coletadas são utilizadas para:
          {/* <ul>
            <li>Gerenciamento de sua conta e prestação de serviços;</li>
            <li>Envio de comunicações importantes relacionadas à sua associação;</li>
            <li>Melhoria do site e da experiência do usuário.</li>
          </ul> */}
          <CenteredList>
            <ListItem>Gerenciamento de sua conta e prestação de serviços;</ListItem>
            <ListItem>Envio de comunicações importantes relacionadas à sua associação;</ListItem>
            <ListItem>Melhoria do site e da experiência do usuário.</ListItem>
          </CenteredList>
        </Paragraph>

        <SectionTitle>3. Compartilhamento de Informações</SectionTitle>
        <Paragraph>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para o cumprimento de obrigações legais ou para a execução dos serviços contratados.</Paragraph>

        <SectionTitle>4. Segurança</SectionTitle>
        <Paragraph>
          Implementamos medidas de segurança adequadas para proteger suas informações pessoais. No entanto, lembre-se de que nenhuma transmissão de dados pela internet é 100% segura.
        </Paragraph>

        <SectionTitle>5. Cookies</SectionTitle>
        <Paragraph>
          Utilizamos cookies para melhorar a funcionalidade do site e sua experiência de navegação. Você pode configurar seu navegador para recusar cookies, mas isso pode limitar o uso de certas
          funcionalidades.
        </Paragraph>

        <SectionTitle>6. Alterações na Política de Privacidade</SectionTitle>
        <Paragraph>Esta política pode ser alterada periodicamente. Quaisquer mudanças serão publicadas nesta página, e recomendamos que você revise este documento regularmente.</Paragraph>

        <SectionTitle>7. Contato</SectionTitle>
        <Paragraph>
          Se você tiver dúvidas sobre esta Política de Privacidade ou sobre como tratamos suas informações, entre em contato conosco através do e-mail <Highlight>contato@talissonbr.com.br</Highlight>.
        </Paragraph>
      </Content>
    </Layout>
  )
}

export default PoliticaDePrivacidade
