import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../hook/useAuth'

interface PrivateRouteProps {
  element: JSX.Element
  private: boolean
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, private: isPrivate }) => {
  const { isAuthenticated } = useAuth()

  if (isPrivate && !isAuthenticated) {
    return <Navigate to="/" replace />
  }

  return <>{element}</>
}

export default PrivateRoute
