import { z } from 'zod'

const envSchema = z.object({
  REACT_APP_API_URL: z.string().url('Por favor, insira uma URL de API válida.'),
  REACT_APP_API_KEY: z.string(),
})

// Verifique se as variáveis estão presentes no ambiente
const parsedEnv = envSchema.safeParse(process.env)

if (!parsedEnv.success) {
  console.error('Erro de validação das variáveis de ambiente:', parsedEnv.error.format())
  throw new Error('Variáveis de ambiente inválidas!')
}

export const env = parsedEnv.data
