import React from 'react'
import Layout from '../Layout'
import { useForm, SubmitHandler } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextField, Box, Typography } from '@mui/material'
import { toast } from 'react-hot-toast'
import CustomButton from '../CustoButtonSX'
import styled from 'styled-components'
import CustomInput from '../CustomInput'

// Schema de validação usando Zod
const registroSchema = z
  .object({
    nome: z.string().min(3, 'O nome deve ter no mínimo 3 caracteres.'),
    email: z.string().email('Insira um e-mail válido.'),
    cpf: z.string().length(11, 'O CPF deve ter 11 dígitos.').regex(/^\d+$/, 'O CPF deve conter apenas números.'),
    senha: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres.'),
    confirmarSenha: z.string(),
  })
  .refine(data => data.senha === data.confirmarSenha, {
    path: ['confirmarSenha'],
    message: 'As senhas não coincidem.',
  })

// Tipagem do formulário
type RegistroFormInputs = z.infer<typeof registroSchema>

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`

const FormTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`

const StyledForm = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`

const Registro: React.FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<RegistroFormInputs>({
    resolver: zodResolver(registroSchema),
  })

  const onSubmit: SubmitHandler<RegistroFormInputs> = data => {
    toast.success('Cadastro realizado com sucesso!')
    console.log('Dados enviados:', data)
    // Aqui você pode adicionar o código para enviar os dados para a API
  }

  return (
    <Layout>
      <Container>
        <FormTitle>Faça seu cadastro</FormTitle>

        <StyledForm as="form" onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400 }}>
          <CustomInput
            label="Nome"
           
            // error={!!errors.nome}
            // helperText={errors.nome?.message}

            placeholder="Digite seu nome"
            type="nome"
            value={getValues('nome')}
            {...register('nome')}
            isError={!!errors.nome}
            helpText={errors.nome?.message || 'Tudo certo!'}
          />
          <TextField label="Email" type="email" {...register('email')} error={!!errors.email} helperText={errors.email?.message} />
          <TextField label="CPF" {...register('cpf')} error={!!errors.cpf} helperText={errors.cpf?.message} />
          <TextField label="Senha" type="password" {...register('senha')} error={!!errors.senha} helperText={errors.senha?.message} />
          <TextField label="Confirmar Senha" type="password" {...register('confirmarSenha')} error={!!errors.confirmarSenha} helperText={errors.confirmarSenha?.message} />

          <CustomButton label="Cadastrar" type="submit" variant="contained" customcolor="#2A2532" />
        </StyledForm>
      </Container>
    </Layout>
  )
}

export default Registro
