import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

// Interface para definir as propriedades aceitas pelo botão
interface CustomButtonProps {
  label: string // Texto do botão
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'inherit'
  customcolor?: string // Cor personalizada para o fundo
  textColor?: string // Cor personalizada para o texto
  variant?: 'contained' | 'outlined' | 'text' // Variante do botão
  onClick?: () => void // Função a ser executada ao clicar
  startIcon?: React.ReactNode // Ícone antes do texto
  endIcon?: React.ReactNode // Ícone após o texto
  disabled?: boolean // Desabilitar o botão
  fullWidth?: boolean // Botão com largura total
}

// Criando estilos personalizados para o botão usando styled
const StyledButton = styled(Button)<{ customcolor?: string; textColor?: string }>(({ customcolor, textColor }) => ({
  textTransform: 'none', // Remove o texto em caixa alta padrão
  borderRadius: '8px', // Bordas arredondadas
  fontSize: '1rem', // Tamanho da fonte
  padding: '0.5rem 1.5rem', // Espaçamento interno
  backgroundColor: customcolor || 'inherit', // Cor personalizada ou padrão
  color: textColor || (customcolor ? '#fff' : 'inherit'), // Cor do texto personalizada ou branco para contraste
  '&:hover': {
    backgroundColor: customcolor ? `${customcolor}CC` : undefined, // Cor ao passar o mouse (com opacidade)
  },
}))

// Componente funcional
const CustomButton: React.FC<CustomButtonProps & React.ComponentProps<typeof Button>> = ({
  label,
  color = 'primary',
  customcolor,
  textColor,
  variant = 'contained',
  onClick,
  startIcon,
  endIcon,
  disabled = false,
  fullWidth = false,
  ...rest // Captura outras propriedades
}) => {
  return (
    <StyledButton
      color={!customcolor ? color : undefined} // Define a cor do Material-UI apenas se customcolor não estiver definido
      variant={variant}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      fullWidth={fullWidth}
      customcolor={customcolor}
      textColor={textColor}
      {...rest} // Adiciona as propriedades extras no botão
    >
      {label}
    </StyledButton>
  )
}

export default CustomButton
