import React from 'react'
import Layout from '../Layout'
import styled from 'styled-components'
import CustomButton from '../CustoButtonSX'
import logoMarca from '../../assets/error404.png'

const Container = styled.div`
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem; 

  @media (max-width: 768px) {
    flex-direction: column; 
    padding: 1rem;
  }
`

const ImageContainer = styled.div`
  flex: 1; 
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }
`

const TextContainer = styled.div`
  flex: 2; 
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem; 
  }

  @media (max-width: 480px) {
    font-size: 1.5rem; 
  }
`

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1rem; 
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`

const Error404: React.FC = () => {
  return (
    <Layout>
      <Container>
        <ImageContainer>
          <img src={logoMarca} alt="Logo" />
        </ImageContainer>
        <TextContainer>
          <Title>Página não encontrada!</Title>
          <Paragraph>
            <CustomButton label="Voltar para a página inicial" variant="text" textColor="#35185A" customcolor="#F4F4F5" type="submit" size="large" href="/" />
          </Paragraph>
        </TextContainer>
      </Container>
    </Layout>
  )
}

export default Error404
