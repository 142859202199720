import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import CustomButton from './CustoButtonSX'
import logoMarca from '../assets/logo.png'
import logoMarca2 from '../assets/LogoMarca2.png'

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.header};
  color: white;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: ${({ hidden }) => (hidden ? 'translateY(-100%)' : 'translateY(0)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
`

const Botao = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`

const Logo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  img {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1); /* Efeito de hover */
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }
  }

  /* Logo maior em telas maiores */
  .logo-large {
    width: 220px;
    height: 80px;

    @media (max-width: 768px) {
      display: none; /* Oculta a logo grande em telas menores */
    }
  }

  /* Logo menor em telas menores */
  .logo-small {
    display: none;
    width: 60px;
    height: 60px;

    @media (max-width: 768px) {
      display: block; /* Exibe a logo pequena em telas menores */
    }
  }
`

const Header: React.FC = () => {
  const currentLocation = useLocation()
  const hideButtonsOnRoutes = ['/login', '/registro'] // Adicione as rotas aqui
  const shouldHideButtons = hideButtonsOnRoutes.includes(currentLocation.pathname)
  return (
    <HeaderContainer>
      <Logo href="/">
        <img src={logoMarca} alt="Logo Grande" className="logo-large" />
        <img src={logoMarca2} alt="Logo Pequena" className="logo-small" />
      </Logo>
      {!shouldHideButtons && (
        <Botao>
          <CustomButton label="Criar conta" variant="outlined" textColor="#18131C" customcolor="#F4F4F5" type="submit" size="large" href="/registro" />
          <CustomButton label="Entrar" variant="contained" customcolor="#2A2532" type="submit" size="large" href="/login" />
        </Botao>
      )}
    </HeaderContainer>
  )
}

export default Header
