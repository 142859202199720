import React from 'react'
import styled from 'styled-components'
import Layout from '../Layout'
import logoMarca from '../../assets/transformation.png'
// import logoMarca2 from '../../assets/logo.png'

// Estilos responsivos para o layout
const Container = styled.div`
  padding: 2rem;
  display: flex;  
  align-items: center; /* Alinha itens ao centro verticalmente */
  gap: 2rem; /* Espaço entre a imagem e o texto */

  @media (max-width: 768px) {
    flex-direction: column; /* Empilha a imagem e o texto em telas menores */
    padding: 1rem;
  }
`

const ImageContainer = styled.div`
  flex: 1; /* Garante que a imagem ocupe espaço proporcional */
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }
`

// const ImageTitulo = styled.div`
//   flex: 1; /* Garante que a imagem ocupe espaço proporcional */
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   img {
//     max-width: 20%;
//     height: 20%;
//   }
// `

const TextContainer = styled.div`
  flex: 2; /* Garante que o texto ocupe mais espaço proporcional */
`

const Title = styled.h1`
  font-size: 2.5rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem; /* Ajusta o tamanho do título em telas menores */
  }

  @media (max-width: 480px) {
    font-size: 1.5rem; /* Menor ainda em telas muito pequenas */
  }
`

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1rem; /* Ajusta o tamanho da fonte em telas menores */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Ainda menor em telas muito pequenas */
  }
`

const Highlight = styled.span`
  color: #18131C;
  font-weight: bold;
`
const Home: React.FC = () => {
  return (
    <Layout>
      <Container>
        {/* Imagem posicionada na frente do texto */}
        <ImageContainer>
          <img src={logoMarca} alt="Company Logo" />
        </ImageContainer>
        {/* Texto */}
        <TextContainer>
          <Title>Seja-bem vindo ao site de Tálison {'{br}'}</Title>
          <Paragraph>
            <br />
            <br />
            Saiba mais sobre o site projetado e desenvolvido por <Highlight>Talisson</Highlight>.
          </Paragraph>
        </TextContainer>
      </Container>
    </Layout>
  )
}

export default Home
