import React from 'react'
import Layout from '../Layout'
import styled from 'styled-components'

const Content = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
`

const Title = styled.h1`
  font-size: 2rem;
  color: #18131C;
  text-align: center;
  margin-bottom: 1.5rem;
`

const SectionTitle = styled.h2`
  font-size: 1.2rem;
  color: #2A2532;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
`

const TermosDeUso: React.FC = () => {
  return (
    <Layout>
      <Content>
        <Title>Termos de Uso</Title>
        <SectionTitle>1. Aceitação dos Termos</SectionTitle>
        <Paragraph>Ao acessar e utilizar este site, você concorda com os termos e condições aqui descritos. Caso não concorde, por favor, não utilize o site.</Paragraph>

        <SectionTitle>2. Alterações nos Termos</SectionTitle>
        <Paragraph>
          Nos reservamos o direito de modificar estes termos a qualquer momento. Quaisquer alterações serão comunicadas nesta página e passarão a vigorar imediatamente após a publicação.
        </Paragraph>

        <SectionTitle>3. Uso Permitido</SectionTitle>
        <Paragraph>
          Você concorda em utilizar o site de acordo com todas as leis e regulamentos aplicáveis. O uso do site para atividades ilegais, fraudulentas ou que infrinjam os direitos de terceiros é
          estritamente proibido.
        </Paragraph>

        <SectionTitle>4. Propriedade Intelectual</SectionTitle>
        <Paragraph>
          Todo o conteúdo disponível no site, incluindo textos, gráficos, imagens, vídeos e logotipos, é de propriedade da organização e protegido por leis de direitos autorais. É proibida a
          reprodução, distribuição ou uso não autorizado desses materiais sem o consentimento prévio.
        </Paragraph>

        <SectionTitle>5. Limitação de Responsabilidade</SectionTitle>
        <Paragraph>A organização não será responsável por quaisquer danos diretos, indiretos, incidentais ou consequenciais decorrentes do uso ou da incapacidade de usar o site.</Paragraph>

        <SectionTitle>6. Links Externos</SectionTitle>
        <Paragraph>Nosso site pode conter links para sites de terceiros. Não nos responsabilizamos pelo conteúdo, políticas ou práticas de privacidade desses sites.</Paragraph>

        <SectionTitle>7. Encerramento do Acesso</SectionTitle>
        <Paragraph>Reservamo-nos o direito de suspender ou encerrar o seu acesso ao site a qualquer momento, sem aviso prévio, se você violar estes Termos de Uso.</Paragraph>
      </Content>
    </Layout>
  )
}

export default TermosDeUso
