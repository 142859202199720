export const theme = {
  colors: {
    header: '#F4F4F5', // Azul
    navigation: '#F4F4F5', // Vermelho
    main: '#F4F4F5', // Amarelo
    footer: '#F4F4F5', // Verde
    footerText: '#5E5560',
    footerLinkHover: '#18131C',
    primary: '#18131C',
    secondary: '#2A2532',
    accent: '#5E5560',
  },
}
