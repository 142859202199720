import React from 'react'
import styled from 'styled-components'
import { FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import logoMarca from '../assets/LogoMarca.png'

const FooterContainer = styled.footer`
  background-color: #111;
  color: #fff;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
    padding: 40px 20px;
  }
`

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const LogoContainer = styled.div`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const ImageContainer = styled.div`
  flex: 1; /* Garante que a imagem ocupe espaço proporcional */
  display: flex;
  justify-content: center;
 width: 80px;
  img {
    max-width: 100%;
    height: auto;
  }
`

const Column = styled.div`
  margin: 20px 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`

const Title = styled.h5`
  font-weight: bold;
  margin-bottom: 10px;
  color: #f4f4f4;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
`

const ListItem = styled.li`
  margin-bottom: 10px;
`

const Link = styled.a`
  color: #ddd;
  text-decoration: none;

  &:hover {
    color: #00d8ff;
  }
`

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }

  a {
    color: #fff;
    font-size: 24px;

    &:hover {
      color: #00d8ff;
    }
  }
`

const Copyright = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: #bbb;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoContainer>
          <ImageContainer>
            <img src={logoMarca} alt="Company Logo" />
          </ImageContainer>
        </LogoContainer>

        <Column>
          <Title>Navegação</Title>
          <List>
            <ListItem>
              <Link href="/">Home</Link>
            </ListItem>
            <ListItem>
              <Link href="/sobre">Sobre</Link>
            </ListItem>
          </List>
        </Column>

        <Column>
          <Title>Contato</Title>
          <List>
            <ListItem>
              <Link href="mailto:contato@talissonbr.com.br">contato@talissonbr.com.br</Link>
            </ListItem>
          </List>
          <SocialIcons>
            <Link href="https://www.instagram.com/talissonbrsouza" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </Link>
            <Link href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </Link>
            <Link href="https://wa.me/" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </Link>
          </SocialIcons>
        </Column>

        <Column>
          <Title>Legal</Title>
          <List>
            <ListItem>
              <Link href="/politica-de-privacidade">Política de Privacidade</Link>
            </ListItem>
            <ListItem>
              <Link href="/termo-de-uso">Termos de Uso</Link>
            </ListItem>
          </List>
        </Column>
      </FooterContent>

      <Copyright>© 2024 Todos os direitos reservados - TBAS.</Copyright>
    </FooterContainer>
  )
}

export default Footer
