import CryptoJS from 'crypto-js'

export const SECRET_KEY = 'agus32224-39241='

export function criptografar(pTexto: string): string {
  if (!pTexto) {
    return ''
  }

  const resposta = CryptoJS.AES.encrypt(pTexto, SECRET_KEY).toString()
  return resposta
}

export function descriptografar(pTexto: string): string {
  if (!pTexto) {
    return ''
  }

  const resposta = CryptoJS.AES.decrypt(pTexto, SECRET_KEY).toString(CryptoJS.enc.Utf8)
  return resposta
}

function buildKey(pChavePrincipal: string, pChaveOculta: string): string {
  pChaveOculta = criptografar(pChaveOculta)
  return `${pChavePrincipal}:${pChaveOculta}`
}

export function setEncryptedItem(pChavePrincipal: string, pChaveSecundaria: string, pValor: string): void {
  const key = buildKey(pChavePrincipal, pChaveSecundaria)
  const encryptedValue = criptografar(pValor)
  localStorage.setItem(key, encryptedValue)
}

export function getDecryptedItem(pChavePrincipal: string, pChaveSecundaria: string, pDefault?: string): string | undefined {
  if (!pChaveSecundaria && !pChavePrincipal) {
    return ''
  }

  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i) || ''
    if (key && key.startsWith(pChavePrincipal)) {
      let [, chave] = key.split(':')
      chave = descriptografar(chave)
      if (chave === pChaveSecundaria) {
        const encryptedValue = localStorage.getItem(key)
        if (!encryptedValue) return ''

        try {
          const decryptedValue = descriptografar(encryptedValue)
          if (pDefault === 'JSON') {
            return decryptedValue ? JSON.parse(decryptedValue) : ''
          }
          return decryptedValue
        } catch (error) {
          console.error('Erro ao descriptografar:', error)
          return '' // Retorna string vazia em caso de erro
        }
      }
    }
  }
}

// Função para remover o item do localStorage
export function removeEncryptedItem(pChavePrincipal: string, pChaveSecundaria: string): void {
  const key = buildKey(pChavePrincipal, pChaveSecundaria)
  localStorage.removeItem(key)
}

export function removeAllWithPrefix(pChavePrincipal: string): void {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key && key.startsWith(pChavePrincipal)) {
      localStorage.removeItem(key)
    }
  }
}
