import React from 'react'
import Layout from '../Layout'
import styled from 'styled-components'
import logoMarca from '../../assets/logo.png'

const Content = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
`

const Title = styled.h1`
  font-size: 2rem;
  color: #18131C;
  text-align: center;
  margin-bottom: 1.5rem;
`

// const SectionTitle = styled.h2`
//   font-size: 1.5rem;
//   color: #18131C;
//   margin-bottom: 1rem;
// `

const Paragraph = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
`

const ImageContainer = styled.div`
  flex: 1; /* Garante que a imagem ocupe espaço proporcional */
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
    height: auto;
  }
`

const Sobre: React.FC = () => {
  return (
    <Layout>
      <Content>
        <Title>Sobre Talisson</Title>
        <Paragraph>
          Natural de São de Francisco - MG, sou amante dos computadores desde a minha adolescência. Nos curtos momentos de uso no laboratório de informática da escola, aquela máquina, então grande e
          branca, me intrigava e seduzia ao mesmo tempo.
        </Paragraph>
        <Paragraph>
          Fui crescendo e junto crescia a minha curiosidade e interesse pelo computador. Hoje, mais de 15 anos se passaram e eu permaneço diante dos computadores, sendo desafiado pelas telas e
          códigos.
        </Paragraph>

        <ImageContainer>
          <img src={logoMarca} alt="Company Logo" />
        </ImageContainer>

        {/* <SectionTitle>Missão</SectionTitle>
        <Paragraph>Fornecer soluções tecnológicas de alta qualidade para impulsionar o sucesso de nossos clientes, promovendo eficiência e transformação digital.</Paragraph>

        <SectionTitle>Visão</SectionTitle>
        <Paragraph>Ser reconhecida como líder em tecnologia e inovação, contribuindo para o crescimento sustentável de nossos clientes e da sociedade.</Paragraph> */}
      </Content>
    </Layout>
  )
}

export default Sobre
